.videollamada {
  height: 100vh;
  position: relative;
  padding: 20px;
  background-color: black;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.videollamada-conocido {
  /*height: calc(100vh - 75px);*/
  position: relative;
  padding: 20px;
}

.videollamada .video {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.videollamada h5 {
  font-weight: 800;
  color: #fff;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.8);
  text-align: left;
}

.videollamada .close {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.videollamada .close span {
  color: #fff;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.8);
  line-height: 1px;
  font-size: 1.5em;
}

.btn-llamada {
  position: absolute;
  bottom: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding-bottom: 20px;
  z-index: 2;
  left: 0;
}
.btn-llamada.from-lobby {
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  z-index: 2;
  left: 0;
  margin-top: 1.5em;
  position: relative;
}

.button-visitor {
  max-height: 45px;
}

.llamada .botton {
  border-radius: 50px;
  padding: 7px 20px 6px;
  border: none;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  margin: 0 8px;
  font-size: 18px;
}

.llamada .btn-primary,
.llamada .btn-primary:not([href]):not([tabindex]) {
  background-color: #00bddd;
  color: #fff;
}

.llamada .btn-primary#btn-abrir.active {
  display: none;
}

.llamada .addCedula {
  width: 50%;
  float: left;
  position: relative;
  background-color: #fff;
  padding: 10px 2%;
  height: 71px;
}

.llamada .addCedula label {
  pointer-events: none;
  border: 2px solid #00bddd;
  border-radius: 50px;
  padding: 5px 10px;
}

.llamada .addCedula input {
  position: relative;
  top: -50px;
  opacity: 0;
}

.btn-llamada .btn-secondary,
.btn-llamada .btn-secondary:not([href]):not([tabindex]) {
  background-color: #fff;
  color: #00bddd;
}

.btn-llamada select {
  padding: 7px 20px 6px;
  border-radius: 50px;
  border: none;
  font-size: 18px;
}

.opc-llamada {
  height: 40%;
  position: absolute;
  width: 100%;
  background-color: #fff;
  z-index: 20;
  left: 0;
  right: 0;
  bottom: 0;
}

.opc-llamada .buscador {
  margin-top: -20px;
}

.opc-llamada .buscador .back-btn {
  position: absolute;
  left: 1em;
  background-color: #00bddd;
  color: #fff;
  height: 44px;
  border: none;
  width: 44px;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-btn span {
  width: 15px;
}

.opc-llamada .buscador input[type="text"] {
  background-color: #00bddd;
  color: #fff;
  font-weight: 800;
  border-radius: 50px;
  padding: 12px 19px 8px;
}

.opc-llamada .buscador input[type="submit"] {
  color: #fff;
  width: 18px;
  height: 18px;
  background-image: url(../img/ico-search.svg);
  background-repeat: no-repeat;
  background-position: center;
  margin-left: -42px;
  top: -6px;
  font-size: 0;
}

.opc-llamada #knowns-list ul {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  margin-top: 40px;
  padding: 0;
  min-height: 150px;
  overflow: auto;
}

.opc-llamada #knowns-list ul li {
  list-style: none;
}

.opc-llamada #knowns-list ul li a {
  display: block;
  cursor: pointer;
}

.opc-llamada #knowns-list ul li img {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  object-fit: cover;
  object-position: center;
  float: left;
  margin: 0 25px;
}

.opc-llamada #knowns-list ul li p {
  text-align: left;
  font-size: 18px;
}

.opc-llamada #knowns-list .btnMas {
  position: absolute;
  bottom: 0.5em;
  right: 0.5em;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00bddd;
  border-radius: 999px;
  color: #ffffff;
  font-size: 1.7em;
}

.opc-llamada .tab-pane {
  padding-bottom: 20px;
}

.opc-llamada #desconocido .foto-perfil div {
  background-image: url(../img/ico-camara.svg);
  background-repeat: no-repeat;
  background-size: 63%;
  background-position: center;
  background-color: #00bddd;
  width: 20vw;
  height: 20vw;
}

.opc-llamada #desconocido .foto-perfil div:after {
  display: none;
}

.opc-llamada #desconocido .foto-perfil input {
  background-image: none;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  opacity: 0;
}

.opc-llamada #desconocido .foto-perfil input::before {
  display: none;
}

.opc-llamada #desconocido .foto-perfil {
  margin: 40px 0;
}

.opc-llamada #desconocido h3,
.opc-abrir #no-disponible h3 {
  font-weight: 800;
  color: #494949;
}

.opc-llamada form input[type="text"] {
  width: 70%;
  text-align: center;
  color: #494949;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px 4px;
  position: relative;
  margin: 0.2em 0.4em;
}

.opc-llamada form input::placeholder {
  color: #494949;
}

.opc-llamada form button::before {
  content: none;
}

.opc-llamada form button {
  width: 40px;
  height: 40px;
  padding: 7px !important;
  margin: 0 1em;
}

.opc-llamada form input[type="submit"] {
  width: 50%;
  width: auto;
  margin: 0.5em;
}

.opc-llamada form #knowns-listnombre {
  font-weight: 800;
  font-size: 24px;
}

.opc-abrir {
  position: relative;
  z-index: 10;
  width: 100%;
}

.opc-abrir #no-disponible {
  background-color: #fff;
  padding-bottom: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.btn-llamada .close-up {
  position: fixed;
  width: 100%;
  height: 86%;
  bottom: 75px;
  left: 0;
  background-color: transparent;
  z-index: -1;
}

.btn-grabar {
  z-index: 20;
  position: relative;
  background-color: #00bddd;
  color: #fff;
  border-radius: 50px;
  padding: 4px 20px 2px;
  border: none;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: -79px !important;
  pointer-events: none;
  cursor: pointer;
}

.btn-grabar + input[type="file"] {
  opacity: 0;
  cursor: pointer;
}

.hidden-canvas {
  display: none;
}

video {
  width: 100%;
  margin: 1em 0;
}

.visitor-type-buttons {
  position: absolute;
  bottom: 1em;
  align-items: center;
  left: 1em;
  right: 1em;
}

.text-container {
  margin-top: 3em;
}

.text-container p {
  font-weight: bold;
  color: #00bddd;
}

.form-images-container {
  margin-top: 2em;
  display: flex;
  height: 100px;
  width: 100%;
}

.panel-images-container {
  margin-top: 1em;
  display: flex;
  height: 100px;
  width: 100%;
}

.images-container {
  position: absolute;
  bottom: 100px;
  display: flex;
  flex-direction: row;
  max-height: 120px;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
}

.photo-container {
  border: 1px solid #ccc;
  position: relative;
  width: 50%;
}

.photo-container span.form-icon {
  color: #ccc;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -21px;
  font-size: 3em;
}

.photo-container span {
  color: #ccc;
  position: absolute;
  top: 0;
  left: 50%;
}

.photo-container img {
  height: 100px;
  width: auto;
  box-shadow: 1px 1px 4px -2px rgba(204, 204, 204, 1);
}

form.contact-form {
  padding: 0.9em 0px;
  margin: 1em;
  height: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  min-height: 0;
}

.foto-user div {
  margin: 0 !important;
}

.error-container {
  position: fixed;
  top: 1em;
  right: 0;
  width: 100%;
}

@media (max-width: 768px) {
  .llamada .botton {
    font-size: 14px;
  }
}
