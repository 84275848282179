.image-loader-container {
  display: flex;
  flex-direction: row;
  width: auto;
  height: 100px;
  align-items: center;
  padding: 0.3em;
  justify-content: center;
}

.button-trigger {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0.5em !important;
  width: 60%;
  min-width: 60%;
  height: 50px;
  background-color: white;
  color: #00bddd;
  font-size: 0.9em;
}

.button-trigger:active {
  border-color: white !important;
  background-color: white !important;
  color: #00bddd !important;
}

.button-trigger:hover {
  border-color: white !important;
  background-color: #00bddd !important;
  color: #ffffff !important;
}

.image-loader-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-loader-container img {
  height: 75px;
  width: auto;
}
