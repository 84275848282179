.modal-body {
  display: flex;
  flex-direction: column;
}
#peerVideoVisitante {
  width: 100%;
  height: 100%;
}
#localVideo-new {
  padding: 10px;
  width: 100%;
}
