.countdown {
  color: #00bfdf;
  font-size: 23px;
  font-weight: 800;
}

.votaBotones {
  float: right;
}

.votaSi {
  width: 50px;
  height: 50px;
  background-color: #00bfdf;
  border-radius: 50%;
  display: block;
  background-image: url(../img/ico-vota.svg);
  background-size: 58%;
  background-position: center;
  background-repeat: no-repeat;
  float: left;
  margin-right: 20px;
  cursor: pointer;
}

.votaNo {
  width: 50px;
  height: 50px;
  background-color: #00bfdf;
  border-radius: 50%;
  display: block;
  transform: rotate(180deg);
  background-image: url(../img/ico-vota.svg);
  background-size: 58%;
  background-position: center;
  background-repeat: no-repeat;
  float: left;
  cursor: pointer;
}

.votaBotones a:disabled {
  background-color: #919191;
}

.estado-correspondencia {
  width: 40px;
  height: 40px;
  display: block;
  background-image: url(../img/ico-corespondencia-pendiente.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 22px;
  right: 21px;
}

.estado-correspondencia.pendiente {
  background-image: url(../img/ico-corespondencia.svg);
}

.fecha {
  color: #00bfdf;
  font-weight: 800;
}

@media (max-width:520px) {
  .countdown {
    padding-left: 0;
    font-size: 20px;
    font-weight: 600;
}

  .votaBotones {
    max-width: 40%;
    padding: 0 !important;
  }
}