.alerta {
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
}

.alerta .modal-content {
  border-radius: 0;
  border: none;
}

.alerta .modal-header {
  position: relative !important;
  border-bottom: none;
  overflow: hidden;
  border-radius: 0;
}

.alerta .modal-header:before {
  content: '';
  position: absolute;
  left: -12px;
  top: 0;
  width: 100%;
  height: 100%;
  transform: skew(-20deg);
  background-color: #00bfdf;
}

.alerta .modal-header .modal-title {
  color: #fff;
  z-index: 2;
}

.alerta .modal-header .modal-title .App-logo,
.alerta .modal-header .modal-title .logo-peque {
  height: 18px;
  margin-top: 0;
}

.alerta .modal-header .close {
  top: 15px !important;
  font-size: 1.2em !important;
  color: #fff;
  margin-right: 0;
}

.alerta .modal-body {
  font-size: 1.4em;
  margin: 1em auto;
  text-align: center;
}

.alerta .modal-body.calling {
  animation: shake 4s ease infinite;
  transform-origin: 50% 50%;
}

.alerta .modal-body h2 {
  font-weight: 800;
  color: #00bfdf;
  font-size: 20px;
}

.alerta .modal-body p {
  padding: 0 2em;
}

.alerta .modal-footer {
  padding: 0.8em;
  border-top: none;
  justify-content: center;
}

.alerta .modal-footer .btn-primary {
  background-color: #00bfdf;
  border: none;
  padding: 0.375rem 1.3rem;
}

.alerta .btn-rechazar {
  background-color: red;
  color: #fff;
  padding: 10px 19px 9px;
  border-radius: 77px;
  width: 110px;
  margin-right: 20%;
  margin-bottom: 30px;
}

.alerta .btn-aceptar {
  background-color: green;
  color: #fff;
  padding: 10px 19px 9px;
  border-radius: 77px;
  width: 110px;
  margin-bottom: 30px;
}

.alerta.llamando .modal-content {
  height: 74vh;
}

.alerta.llamando .modal-content .modal-body {
  padding-top: 10%;
}

.styles_overlay__CLSq- {
  padding: 0;
}

.btn-security {
  background-color: tomato !important;
}

@media (max-width: 768px) {
  .modal-footer.events {
    display: flex;
    flex-direction: column;
  }

  .alerta {
    width: 90%;
  }

  .btn-event {
    min-width: 80%;
    max-width: 80%;
    margin: 0.3em;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
