.mapa {
  width: 100%;
  margin: 0 auto;
}

.mapa div {
  position: relative;
}

.mapa-img {
  width: 100%;
  object-fit: contain;
}

.btn-camara {
  cursor: pointer;
  padding: 3px 12px 0px;
  border-radius: 15px;
  background-color: #5e5f5f;
  color: #fff !important;
  font-weight: 800;
  position: absolute;
}

.btn-camara:before {
  content: "";
  position: absolute;
  border-radius: 50%;
  width: 23px;
  height: 25px;
  background-image: url(../img/ico-camera.png);
  background-size: contain;
  background-repeat: no-repeat;
  left: -27px;
}

.mapaHeader h2 {
  color: #5e5f5f;
  margin-bottom: 0;
}

.mapaHeader h2 {
  font-weight: 800;
}

.mapaHeader select {
  background-color: #00bfdf;
  color: #fff;
  border-radius: 10px;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  height: 40px;
  outline: none;
}

.camaras-container {
  margin: auto;
  max-width: 700px;
}

/** Modal */

.styles_overlay__CLSq-.background-modal {
  max-width: 100%;
  top: 0 !important;
}

.styles_modalCenter__L9F2w {
  top: 10px;
}

.modal-container {
  background-color: #FFFFFF;
  padding: 1.6em 1em 0.6em 1em;
  border-radius: 5px;
  display: flex;
  flex-flow: column;
  margin: 1em;
}

.canvas-container {
  /* background-color: bisque; */
  max-width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0.5em;
}

.canvas-container span {
  font-size: 1.2em;
  color: #00bfdf;
  font-weight: bold;
}

canvas {
  height: 100%;
  width: 70%;
}

button.modal-close {
  position: absolute;
  right: 20px;
  top: 15px;
}

.open-door-button {
  background-color: #00bddd;
  color: #FFFFFF
}

.close-door-button {
  background-color: #FFFFFF;
  border: 1px solid #00bddd;
  margin-left: 1em;
  color: #00bddd;
}

.image-container {
  display: flex;
  justify-content: center;
}

.mapa-img {
  height: 65vh;
  width: auto;
}

@media (max-width:768px) {
  .canvas-container span {
    font-size: 0.9em;
  }

  canvas {
    width: 100%;
  }

  .mapa-img {
    height: 100%;
    max-width: 100%;
  }

}