.content-center {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 39;
}

.title {
  margin-bottom: 2em;
  font-weight: bold;
}

.messages {
  margin-top: 2em;
}

p.message {
  font-size: 30px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.pulse i {
  color: #fff;
}

.pulse {
  height: 100px;
  width: 100px;
  background-color: #00bddd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 2em;
}

.pulse::before {
  content: "";
  position: absolute;
  border: 1px solid #00bddd;
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  border-radius: 50%;
  animation: pulse 1s linear infinite;
}

.pulse::after {
  content: "";
  position: absolute;
  border: 1px solid #00bddd;
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  border-radius: 50%;
  animation: pulse 1s linear infinite;
  animation-delay: 0.3s;
}

.ringing-close-button {
  position: fixed;
  top: 10px;
  right: 10px;
  font-size: 75px;
  font-weight: 100;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}
