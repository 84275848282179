.admin-button {
  background-color: transparent;
  border: none;
  position: relative;
  z-index: 2;
  margin: 15px 20px;
  padding: 10px 30px;
  width: 80%;
}

.admin-button:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: -1;
  transform: skewX(-25deg);
  -webkit-box-shadow: 0px 10px 30px 0px rgba(64, 126, 172, 1);
  -moz-box-shadow: 0px 10px 30px 0px rgba(64, 126, 172, 1);
  box-shadow: 0px 10px 30px 0px rgba(64, 126, 172, 1);
}

tr th {
  background-color: #5f5f5f;
  color: #fff;
  letter-spacing: 1px;
}

tr td {
  background-color: transparent;
  word-break: break-word;
}

.btn {
  margin-bottom: 0px !important;
}

.header-admin-dashboard {
  width: 100%;
  position: fixed;
  top: 0;
  overflow: hidden;
  height: 92px;
}

.table-cards,
.table-apartments,
.table-buildings,
.table-users {
  max-height: 300px;
  overflow: auto;
}

.admin-input input {
  height: 50px !important;
}

.server-status {
  background-color: #cb4335;
  padding: 5px;
  color: white;
  text-align: center;
  width: 100%;
  position: fixed;
  bottom: 0px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.server-status span {
  margin: 0px 20px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.swal2-checkbox input {
  transform: scale(1.5)
}

@media (max-width:768px) {
  h2 {
    font-size: 25px;
  }
}