.label-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.label-container label {
  font-size: 1.5em;
  color: #FFFFFF;
}

.read-card-container {
  border-top: 1px solid white;
  margin-top: 1.5em;
  padding-top: 1em;
}


@media (max-width:520px) {
  .configuration-lector-container {
    margin: 1em
  }

  .read-card-container {
    padding: 1em;
  }

  .submit-button-container {
    margin-bottom: 4em;
  }
}