.menuinferior {
  background-color: #fff;
  -webkit-box-shadow: 0px -10px 15px 0px rgba(4, 4, 4, 0.1);
  -moz-box-shadow: 0px -10px 15px 0px rgba(4, 4, 4, 0.1);
  box-shadow: 0px -10px 15px 0px rgba(4, 4, 4, 0.1);
  padding: 0;
  margin-top: 3%;
  z-index: 100;
}

.menuinferior ul {
  position: absolute;
  list-style: none;
  display: flex;
  justify-content: space-around;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  height: 90px;
}

.menuinferior ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  margin: 0;
}

.menuinferior ul li {
  padding: 20px 0;
  position: relative;
}

.menuinferior ul li a,
.menuinferior ul li button {
  width: 50px;
  max-width: 50px;
  height: 35px;
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.menuinferior ul li .notificacion,
.menu-lateral nav .notificacion {
  position: absolute;
  background-color: red;
  padding: 7px 8px 4px;
  border-radius: 50px;
  top: 5px;
  right: -10px;
  color: #fff;
  font-weight: 600;
  line-height: 0.9em;
  min-width: 25px;
  pointer-events: none;
}

.menu-lateral nav .notificacion {
  top: -9px;
  right: -23px;
}

.ico-mensaje {
  background-image: url(../img/ico-mensaje.svg);
}

.ico-correspondencia {
  background-image: url(../img/ico-correspondencia.svg);
}

.ico-panico {
  background-image: url(../img/ico-panico.svg);
}

.ico-llamada {
  background-image: url(../img/gear.svg);
}

.ico-cartelera {
  background-image: url(../img/ico-cartelera.svg);
}

.ico-menu {
  background-image: url(../img/ico-menu.svg);
}

.menuinferior ul li a.active:before,
.menuinferior ul li a:hover:before {
  content: '';
  position: absolute;
  width: 140%;
  left: -20%;
  top: 0;
  height: 3px;
  background-color: #62bcdb;
}

.menu-lateral {
  position: fixed;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 1070;
  padding: 35px 30px 10px;
  width: 80%;
  max-width: 500px;
  text-align: right;
}

.menu-lateral:before {
  content: '';
  position: absolute;
  background: rgba(0, 177, 207, 1);
  background: -moz-linear-gradient(top, rgba(0, 177, 207, 1) 0%, rgba(79, 134, 182, 1) 100%);
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(0, 177, 207, 1)),
    color-stop(100%, rgba(79, 134, 182, 1))
  );
  background: -webkit-linear-gradient(top, rgba(0, 177, 207, 1) 0%, rgba(79, 134, 182, 1) 100%);
  background: -o-linear-gradient(top, rgba(0, 177, 207, 1) 0%, rgba(79, 134, 182, 1) 100%);
  background: -ms-linear-gradient(top, rgba(0, 177, 207, 1) 0%, rgba(79, 134, 182, 1) 100%);
  background: linear-gradient(to bottom, rgba(0, 177, 207, 1) 0%, rgba(79, 134, 182, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00b1cf', endColorstr='#4f86b6', GradientType=0);
  height: 100%;
  width: 200%;
  transform: skew(-25deg);
  left: 7%;
  top: 0;
  z-index: -1;
}

@media (max-width: 500px) {
  .menu-lateral:before {
    left: 0;
  }
}

.menu-lateral .logo-peque {
  width: 40% !important;
}

.menu-lateral nav {
  display: flex;
  flex-direction: column;
  align-content: baseline;
  height: 70%;
  position: relative;
}

.menu-lateral nav ul {
  bottom: 0;
  right: 0;
  position: absolute;
}

.menu-lateral nav li {
  list-style: none;
  margin-bottom: 10%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.menu-lateral nav a {
  text-align: right;
  color: #fff;
  font-weight: 900;
  font-size: 19px;
  margin-bottom: 4%;
}

.menu-lateral p {
  color: #fff;
  font-size: 12px;
  text-align: right;
}

.menu-lateral p a {
  font-weight: 800;
  color: #fff;
}

.menu-lateral .menuclose {
  position: relative;
  width: 20px;
  height: 20px;
  float: right;
  cursor: pointer;
}

.menu-lateral .menuclose:after {
  position: absolute;
  content: '+';
  color: #fff;
  font-size: 45px;
  font-weight: 100;
  transform: rotate(45deg);
  right: -7px;
  top: -29px;
}

.dropdown-menu {
  position: relative;
  background-color: transparent;
  float: right;
  border: none;
}

.menu-lateral nav .dropdown-menu a {
  font-weight: 100;
  font-size: 18px;
}
