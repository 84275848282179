.styles_overlay__CLSq- {
  top: -30px !important;
  bottom: -30px !important;
}

.terms-condititions-link {
  font-size: 1.1em;
  text-decoration: underline;
  color: #ffffff;
  cursor: pointer;
}

.form-check {
  color: #ffffff;
}

.bg-blue {
  background-color: #00bddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-blue-image {
  width: 70% !important;
}
