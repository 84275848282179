.apartments-list-table > thead {
  color: #ffffff;
}

.apartments-list-table > tbody {
  background-color: #ffffff;
}
.apartments-list-table > tbody > tr > td {
  vertical-align: middle;
}

.call-duplicated-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  margin-bottom: 0px !important;
}

.button-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 650px) {
  .apartments-list-container {
    width: 80%;
    margin: auto;
  }
}
