.slider-cartelera{
    width: 100%;
    height: 62vw;
    max-height: 300px;
    margin-bottom: 38px;
}

.carousel.carousel-slider{
    height: 100%;
}

.carousel .slider-wrapper{
    height: 100%;
}

.slider-cartelera .carousel-inner, .slider-cartelera .slider{
    height: 100%;
}

.slider-cartelera .carousel-item, .slider-cartelera .slider{
    height: 100%;
    width: 100%;
    margin-left: -20%;
    display: block;
    z-index: 0;
}

.slider-cartelera .carousel-item.active, .slider-cartelera .slider.selected{
    left: 0;
    z-index: 20;
}

.slider-cartelera .carousel-item .carousel-content, .slider-cartelera .slider .carousel-content{
    transform: skewX(-25deg);
    overflow: hidden;
    height: 100%;
    left: -18%;
    position: relative;
}

.slider-cartelera .carousel-item img, .slider-cartelera .slider img{
    height: 100%;
    width: 130% !important;
    object-fit: cover;
    transform: skewX(25deg);
    margin-left: -15%;
}

.slider-cartelera .carousel-caption{
    text-align: left;
    left: 7%;
    bottom: 4%;
    width: 70%;
    height: 70%;
    transform: skewX(25deg);
    margin-left: 20%;
}

.slider-cartelera .carousel-caption h5{
    font-weight: 800;
    font-size: 25px;
}

.slider-cartelera .carousel-caption p{
    font-size: 15px;
}

.slider-cartelera .carousel-caption .fechaPublicacion{
    position: absolute;
    top: -30%;
    right: -30px;
}

.slider-cartelera .carousel-indicators{
    margin-bottom: 0;
    bottom: -39px;
    width: 100%;
    margin-left: 0;
    border-bottom: 1px solid #d9d9d9;
}

.slider-cartelera .carousel-indicators li{
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #00bfdf;
}

.noticias ul{
    padding: 0;
    overflow: hidden;
}

.noticias ul li{
    list-style: none;
    text-align: left;
    padding: 20px 20px 0;
    position: relative;
    display: inline-block;
    margin-top: 20px;
    width: 100%;
}

.noticias ul li .notcontenido{
    max-height: 50px;
    overflow: hidden;
}

.noticias ul li::before{
    position: absolute;
    content: "";
    width: 120%;
    height: 100%;
    left: -20%;
    top: 3%;
    transform: skewX(-25deg);
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.15);
    z-index: -1;
}
.noticias ul li .notFecha{
    text-align: right;
    color: #00bfdf;
    font-size: 12px;
    margin-bottom: 0;
}

.noticias ul li .notTitulo{
    font-weight: 800;
    font-size: 21px;
    color: #5e5f5f;
}