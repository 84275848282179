.modalCamara.modal-dialog {
  width: 100%;
  position: absolute;
  bottom: 75px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  margin: 0;
}
.styles_modalCenter__L9F2w {
  width: 90%;
  max-width: 800px;
  display: contents;
  position: unset;
}

.modalCamara .modal-content,
.styles_modal__gNwvD .modal-content {
  background-color: transparent;
}

.modalCamara .modal-header,
.styles_modal__gNwvD .modal-header {
  position: absolute;
  width: 100%;
  border-bottom: none;
  z-index: 20;
  padding-top: 5px;
}

.modalCamara .modal-title,
.styles_modal__gNwvD .modal-title {
  font-weight: 800;
  color: #fff;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.8);
}

.modalCamara .modal-header .close span,
.styles_modal__gNwvD .modal-header .close span {
  color: #fff;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.8);
  line-height: 1px;
  position: absolute;
  right: 15px;
  top: 15px !important;
  font-size: 1.2em !important;
}

.modalCamara .modal-body {
  padding: 0;
  width: 100%;
}

.styles_modal__gNwvD .modal-body {
  padding: 0;
  width: 100%;
}

.modalCamara .modal-body img {
  width: 100%;
  height: 40vh;
  object-fit: cover;
}

.styles_modalCenter__L9F2w {
  width: 100%;
  max-width: 800px;
  position: relative;
  display: block;
  background: transparent;
  padding: 0;
}

.styles_modal__gNwvD .modal-body #peerVideo {
  width: 100%;
  height: calc(100vh - 5em);
  object-fit: cover;
}

.styles_modal__gNwvD .modal-body #localVideo {
  position: absolute;
  bottom: 5px;
  width: 40%;
  right: 4px;
  height: auto;
}

.modalCamara .modal-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: center;
  border-top: none;
}

.modalCamara .modal-footer button {
  border-radius: 50px;
  padding: 4px 20px 2px;
  border: none;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}

.modalCamara .modal-footer .btn-secondary {
  background-color: #fff;
  color: #00bddd;
}

.modalCamara .modal-footer .btn-primary {
  background-color: #00bddd;
}

.modalCamara .modal-dialog.fullsize {
  top: 0;
  max-width: 100%;
}
.modalCamara .modal-dialog.fullsize .modal-body img {
  width: 100%;
  height: calc(100vh - 87px);
}

.modalCamara .modal-dialog .btn-fullsize,
.styles_modal__gNwvD .modal-header .btn-fullsize {
  width: 25px;
  height: 25px;
  background-image: url(../img/ico-fullscreen.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  right: 40px;
  position: absolute;
}

@media (min-width: 576px) {
  .styles_overlay__CLSq- {
    max-width: 500px;
    margin: 1.75rem auto;
  }
}
