/*-- Fuentes --*/
@font-face {
  font-family: 'Brown';
  src:
    url('./assets/fonts/Brown-Bold.woff2') format('woff2'),
    url('./assets/fonts/Brown-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Brown';
  src:
    url('./assets/fonts/Brown-Light.woff2') format('woff2'),
    url('./assets/fonts/Brown-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

/*-- estilos base --*/

html {
  min-height: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Brown', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.error {
  margin-top: -34px;
}

.error-white {
  margin-top: -34px;
  color: #fff;
  margin-left: 22px;
}

.error-white2 {
  margin-top: -18px;
  color: #fff;
  margin-left: 22px;
}

#root {
  height: 100%;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 92% !important;
}

.pointer {
  cursor: pointer;
}

.cancel {
  width: 40px;
  height: 40px;
  display: block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: -4px;
  right: 7px;
}

.close:focus,
.close:hover {
  border: none !important;
  text-decoration: none;
  opacity: 0.75;
}

.styles_closeButton__20ID4 {
  display: none !important;
}

.container-new {
  max-width: 700px;
  padding: 13px 0 75px;
}

.DayPickerInput {
  display: inline-block;
  /*transform: skewX(-25deg);*/
  border: 1px solid #fff;
}

.InputFromTo {
  text-align: center;
}

.DayPickerInput-OverlayWrapper {
  position: fixed !important;
}

.styles_overlay__CLSq- {
  top: -160px !important;
}

.pendiente-name {
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.button-close {
  /*padding: 0;*/
  background-color: transparent;
  border: 0;
  padding-right: 10px;
}

.button-span {
  color: #fff;
  font-size: 30px;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
  line-height: 1px;
  position: absolute;
}

button:focus {
  outline: none;
}

.pa-button {
  padding-top: 55px !important;
}

.header-modal-new {
  display: flex;
  justify-content: space-between;
  padding: 25px;
}

.footer-modal-new {
  text-align: center;
  padding: 1rem;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

#video-conocido {
  width: 100%;
}

.select-conocido {
  background-color: #00505e;
  color: #fff;
  border-radius: 50px;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  height: 40px;
  outline: none;
  width: 30%;
}

.button-radius {
  border-radius: 6rem;
  font-weight: 600;
  font-size: 21px;
  background-color: #00505e !important;
  padding: 2px 15px 7px 15px;
}

.output {
  display: none;
}

.open-output {
  display: block !important;
}

.photo {
  border: 1px solid #000;
  margin: 4px;
}

.dropdown-menu.show {
  background-color: gainsboro !important;
}

button#dropdown-basic {
  background-color: #00505e;
  color: #fff;
  border-radius: 50px;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  height: 40px;
  outline: none;
  width: 30%;
}

/** Form styles **/

label.subtitle {
  padding: 1.8em;
  margin: auto;
  font-size: 1.6em;
  text-transform: uppercase;
  color: #ffffff;
}

.link-option-container {
  margin: 1em;
  text-align: center;
  color: #ffffff;
  font-size: 1.2em;
}

.link-option-container .links-container {
  display: flex;
  align-items: center;
}

.link-option-container strong {
  text-decoration: underline;
  cursor: pointer;
}

.link-option-container .link-option:hover {
  font-size: 18px;
  cursor: pointer;
}

.button-form:hover {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  transform: translateY(-0.25em);
}

.error-form-container {
  margin-top: -30px;
  text-align: right;
  width: 100%;
  margin-bottom: 1em;
}

.error-form-image-container {
  margin-top: -10px;
  text-align: center;
  width: 100%;
  margin-bottom: 1em;
}

.error-form-container .error-text {
  color: #fbb74b;
  font-weight: 600;
}

.error-form-image-container .error-image {
  color: #fbb74b;
  font-weight: 600;
}

.error-global-container {
  margin-top: -10px;
  text-align: center;
  width: 100%;
  margin-bottom: 1em;
}

.error-global-container .error-text {
  color: #fbb74b;
  font-weight: 600;
}

::placeholder {
  font-weight: 600;
  color: #fff;
}
