.perfil{
    color: #fff;
    font-family: 'Brown';
}
.perfil h2{
    line-height: 12px;
    font-size: 28px;
    font-weight: bold;
}

.perfil p{
    margin-bottom: 0;
    font-size: 15px;
}

.perfil a{
    font-weight: bold;
    cursor: pointer;
    font-size: 15px;
}

.integrantes{
    margin-top: 20px;
    font-family: 'Brown';
}

.integrantes hr{
    margin-top: 40px;
}

.integrantes ul.nav{
    overflow: hidden;
    background-color: #00bfdf;
}

.integrantes ul.nav li a{
    font-weight: bold;
    color: #fff;
    position: relative;
    z-index: 2;
}

.integrantes ul.nav li a:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #00bfdf;
    transform: skew(-20deg);
    z-index: -1;
}

.integrantes ul.nav li a.active{
    background-color: transparent;
    color: #5e5f5f;
    position: relative;
    z-index: 1;
}

.integrantes ul.nav li a.active:before{
    content: "";
    position: absolute;
    width: 110%;
    height: 100%;
    left: -5%;
    top: 0;
    background-color: #fff;
    transform: skew(-20deg);
    z-index: -1;
}

.integrantes .tab-content{
    background-color: #fff;
    padding: 30px 0;
    position: relative;
}

.integrantes .tab-content::before{
    background-color: #fff;
    content: "";
    position: absolute;
    height: 50vh;
    width: 100%;
    top: 0;
    left: 0;
}

.integrantes .tab-content .lista ul{
    padding: 15px 0;
    overflow: hidden;
}

.integrantes .tab-content .lista li, .integrantes .hora-habil ul li{
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
    min-height: 55px;
    padding-right: 10%;
    margin-bottom: 15px;
}

.integrantes .tab-content .lista li:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: -5%;
    top: 0;
    background-color: #EFF0EB;
    transform: skew(-20deg);
    z-index: -1;
}

.integrantes .tab-content .lista li .datofamiliar, .integrantes .hora-habil ul li .datofamiliar{
    flex-basis: 50%;
}
.integrantes .tab-content .lista li .datofamiliar p:first-child, .integrantes .hora-habil ul li .datofamiliar p:first-child{
    font-weight: bold;
}
.integrantes .tab-content .lista li .datofamiliar p, .integrantes .hora-habil ul li p{
    margin: 0;
    font-size: 12px;
    text-align: left;
}

.integrantes .tab-content .lista li img, .integrantes .hora-habil ul li img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: 3%;
    background-color: #5e5f5f;
}

.integrantes .tab-content .lista li .configFamiliar{
    width: 20px;
    height: 20px;
    background-image: url(../img/gear-gray.svg);
    background-size: contain;
    cursor: pointer;
}

.integrantes .btnMas{
    bottom: 20px;
}


/* estilos configuracion familia */
.integrantes .hora-habil{
    color: #5e5f5f;
    padding: 10px 20px;
}

.integrantes .hora-habil h5{
    font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 800;
    font-size: 16px;
}

.integrantes .hora-habil input[type="range"]{
    padding: 0;
    width: 100%;
    color: #5e5f5f;
    -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: #00bfdf;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.integrantes .hora-habil input::-webkit-slider-thumb{
    background: #5e5f5f;
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: 4px solid #cecdcd;
    border-radius: 50px;
}

.integrantes .hora-habil input[type="range"]:before{
    content: "";
    position: absolute;
    width: 21px;
    height: 21px;
    background: #5e5f5f;
    border-radius: 50px;
    top: -8px;
}

.integrantes .hora-habil input[type="range"]:after{
    content: "";
    position: absolute;
    width: 21px;
    height: 21px;
    background: #5e5f5f;
    border-radius: 50px;
    top: -8px;
    right: 0;
}

.integrantes .hora-habil .days-habil{
    display: flex;
    justify-content: space-around;
}

.integrantes .hora-habil .checkddays{
    display: block;
    position: relative;
    margin: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.integrantes .hora-habil .checkddays input{
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    position: absolute;
}

.integrantes .hora-habil .checkddays input:checked ~ .checkmark{
    color: #fff;
}

.integrantes .hora-habil .checkddays .checkmark{
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    color: #5e5f5f;
    position: relative;
    z-index: 1;
    font-weight: 800;
    padding-top: 10px;
}

.integrantes .hora-habil .checkddays .checkmark:after {
    content: "";
    position: absolute;
    background-color: #cfcece;
}

.integrantes .hora-habil .checkddays input:checked ~ .checkmark:after {
    display: block;
    background-color: #00bfdf;
}

.integrantes .hora-habil ul{
    padding: 0;
}

.integrantes .hora-habil ul li{
    list-style: none;
}

.integrantes .boton-guardar{
    color: #fff;
    position: relative;
    height: 40px;
    width: 60%;
    max-width: 250px;
    margin: 0 auto;
    display: block;
    z-index: 1;
    padding: 10px;
    margin-bottom: 30px;
}

.integrantes .boton-guardar::before{
    content: "";
    width: 100%;
    height: 100%;
    background-color: #00bfdf;
    transform: skew(-20deg);
    left: 0;
    top: 0;
    position: absolute;
    z-index: -1;
}

/* Style the checkmark/indicator */
.checkddays .checkmark:after {
    left: -15px;
    top: -3px;
    width: 45px;
    height: 45px;
    border-width: 0 3px 3px 0;
    border-radius: 50px;
    z-index: -1;
}

header .btn-back{
    background-image: url(../img/arrow-back-blue.svg);
    position: absolute;
}

.familiar{
    display: inline-block;
    position: relative;
    width: 100%;
}
.familiar .foto-perfil{
    margin: 10px 15px;
}

.familiar .foto-perfil div{
    width: 17vw;
    height: 17vw;
}
.familiar .foto-perfil div:after{
    display: none;
}

.familiar .foto-perfil input{
    top: -33px;
    right: -40px;
}

.familiar .foto-perfil input:before{
    background-image: none;
    content: "+";
    color: #fff;
    font-size: 30px;
    background: rgba(127,126,126,1);
    background: -moz-linear-gradient(top, rgba(127,126,126,1) 0%, rgba(72,72,72,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(127,126,126,1)), color-stop(100%, rgba(72,72,72,1)));
    background: -webkit-linear-gradient(top, rgba(127,126,126,1) 0%, rgba(72,72,72,1) 100%);
    background: -o-linear-gradient(top, rgba(127,126,126,1) 0%, rgba(72,72,72,1) 100%);
    background: -ms-linear-gradient(top, rgba(127,126,126,1) 0%, rgba(72,72,72,1) 100%);
    background: linear-gradient(to bottom, rgba(127,126,126,1) 0%, rgba(72,72,72,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7f7e7e', endColorstr='#484848', GradientType=0 );
}

.familiar h2{
    float: left;
    width: 50%;
    color: #fff;
    text-align: left;
    margin-left: 10px;
    margin-top: 35px;
    font-weight: 800;
    line-height: 10px;
}

.familiar p{
    float: left;
    width: 50%;
    color: #fff;
    text-align: left;
    margin-left: 10px;
}

.familiar .btn-editar{
    position: absolute;
    width: 40px;
    height: 40px;
    background-image: url(../img/ico-editar.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
    margin-top: 25px;
}

.datosvehiculo{
    padding-top: 20px;
    margin-left: 10px;
    float: left;
}

.datosvehiculo p{
    margin: 0;
    width: 100%;
    text-transform: uppercase;
    font-size: 20px;
}