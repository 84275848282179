header {
  background-color: #fff;
  padding: 2% 0;
  /* margin-bottom: 3%; */
  display: flex;
  -webkit-box-shadow: 0px 5px 15px 0px rgba(237, 237, 236, 1);
  -moz-box-shadow: 0px 5px 15px 0px rgba(237, 237, 236, 1);
  box-shadow: 0px 5px 15px 0px rgba(237, 237, 236, 1);
  align-items: center;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  height: 92px;
}

.logoblue {
  background-image: url(../img/bluebuilding-blue.svg);
  background-repeat: no-repeat;
  background-position: center;
  height: 50px;
  max-width: 250px;
  width: 80%;
  display: block;
  margin: 0 auto;
}

.userimage {
  width: 10vw;
  height: 10vw;
  max-width: 50px;
  max-height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 3%;
  background-color: #5e5f5f;
  display: block;
  position: absolute;
  right: 10px;
  overflow: hidden;
}

.userimage img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.titulo {
  position: relative;
  width: 100%;
  margin: 0;
  z-index: 1000;
  -webkit-box-shadow: 0px 5px 15px 0px rgba(237, 237, 236, 1);
  -moz-box-shadow: 0px 5px 15px 0px rgba(237, 237, 236, 1);
  box-shadow: 0px 5px 15px 0px rgba(237, 237, 236, 1);
  padding: 1rem 0;
}

.titulo h2 {
  color: #00bfdf;
  font-weight: 800;
  font-size: 24px;
}
