.App {
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 16vh;
  pointer-events: none;
  max-width: 900px;
}

.logo-peque {
  height: 50px;
  max-width: 250px;
}

.App-header {
  background-color: #282c34;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

main {
  max-width: 700px;
  margin: 0 auto;
}

.container {
  max-width: 700px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 75px;
  padding-top: calc(90px + 2vw);
}

.container-dashboard {
  margin: 0px 100px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 75px;
  padding-top: calc(90px + 2vw);
}

.conainter-form {
  margin: 0px 100px;
  padding-left: 0;
  padding-right: 0;
}

.container-section {
  padding-top: 0.5em;
  margin: auto;
  max-width: 700px;
}

.dashboard-container {
  margin: auto;
  position: absolute;
  top: 92px;
  overflow: auto;
  left: 0;
  right: 0;
  bottom: 92px;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .container {
    padding-top: 13%;
  }
}

@media (max-width: 520px) {
  .container {
    padding-top: 70px;
  }
}

.llamada .container {
  padding-top: 0;
}

/* -- botones de navegación -- */

.btn-back {
  width: 25px;
  height: 25px;
  background-image: url(../img/arrow-back.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: block;
  padding: 15px;
  margin-left: 3%;
  cursor: pointer;
}

.code {
  font-size: 1.4em;
}

.btn-primary {
  border-color: #00bddd;
}

.call-button {
  border-radius: 999px;
  margin: 0.5em;
  height: 50px;
  padding: 0.7rem 0.75rem;
}

.call-modal-footer {
  display: flex !important;
  justify-content: space-evenly !important;
}
