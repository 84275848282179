/*-------Colores----*/
.bg-blue {
  background: rgba(0, 177, 207, 1);
  background: -moz-linear-gradient(top, rgba(0, 177, 207, 1) 0%, rgba(79, 134, 182, 1) 100%);
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(0, 177, 207, 1)),
    color-stop(100%, rgba(79, 134, 182, 1))
  );
  background: -webkit-linear-gradient(top, rgba(0, 177, 207, 1) 0%, rgba(79, 134, 182, 1) 100%);
  background: -o-linear-gradient(top, rgba(0, 177, 207, 1) 0%, rgba(79, 134, 182, 1) 100%);
  background: -ms-linear-gradient(top, rgba(0, 177, 207, 1) 0%, rgba(79, 134, 182, 1) 100%);
  background: linear-gradient(to bottom, rgba(0, 177, 207, 1) 0%, rgba(79, 134, 182, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b1cf', endColorstr='#4f86b6', GradientType=0 );
}

.bg-blue .btn {
  margin-bottom: 20px;
}

.bg-blue .container {
  padding-top: 0;
}

.bg-blue .row {
  margin-right: 0;
  margin-left: 0;
}
/*-------estilos de campos, botones y formularios----*/

form {
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: 20px;
  align-content: center;
  min-height: calc(82vh - 150px);
}

input {
  background-color: transparent;
  border: none;
  color: #fff;
  padding: 13px 20px;
  position: relative;
}

input::-webkit-input-placeholder {
  color: #fff;
  opacity: 0.4;
}

input:focus {
  outline: none;
}

input:-internal-autofill-previewed,
input:-internal-autofill-selected,
textarea:-internal-autofill-previewed,
textarea:-internal-autofill-selected,
select:-internal-autofill-previewed,
select:-internal-autofill-selected {
  background-color: transparent !important;
  transform: skewX(0deg) !important;
}

/* -- campos con inclinación --*/

.skew-input {
  margin-bottom: 30px;
  transform: skewX(-25deg);
  border: 1px solid #fff;
  min-width: 48%;
}

.skew-input input,
.skew button,
.skew-input select {
  transform: skewX(25deg);
}

form button,
form .button {
  background-color: transparent;
  color: #5f5f5f;
  position: relative;
  z-index: 2;
  padding: 13px 20px !important;
  letter-spacing: 2px;
  text-transform: uppercase;
}

form .btn:focus {
  outline: none;
  box-shadow: none;
}

form button:before,
form .button:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: -1;
  transform: skewX(-25deg);
  -webkit-box-shadow: 0px 10px 30px 0px rgba(64, 126, 172, 1);
  -moz-box-shadow: 0px 10px 30px 0px rgba(64, 126, 172, 1);
  box-shadow: 0px 10px 30px 0px rgba(64, 126, 172, 1);
}

form button:focus:before {
  -webkit-box-shadow: 0px 1px 2px 0px rgba(50, 156, 194, 1);
  -moz-box-shadow: 0px 1px 2px 0px rgba(50, 156, 194, 1);
  box-shadow: 0px 1px 2px 0px rgba(50, 156, 194, 1);
}

/* -- detalle en los campos -- */
.input-shape,
.input-shape-2,
.button-shape,
.button-shape-2 {
  position: relative;
}

.input-shape:before {
  content: '';
  position: absolute;
  background-image: url(../assets/img/shape-1.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 66px;
  height: 129px;
  transform: skewx(25deg);
  top: -57px;
  left: -33px;
}

.button-shape:after {
  content: '';
  position: absolute;
  background-image: url(../assets/img/shape-1.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 66px;
  height: 129px;
  transform: skewx(0deg);
  top: -57px;
  left: -24px;
}

.input-shape-2:before {
  content: '';
  position: absolute;
  background-image: url(../assets/img/shape-2.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 47px;
  height: 98px;
  transform: skewx(25deg);
  top: -45px;
  right: -25px;
}

.button-shape-2:after {
  content: '';
  position: absolute;
  background-image: url(../assets/img/shape-2.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 47px;
  height: 98px;
  transform: skewx(0deg);
  top: -45px;
  right: -35px;
}

/* -- imagen de usuario y boton de cargar imagen -- */

.foto-user,
.foto-perfil {
  margin: 10px auto;
}
.foto-user div,
.foto-perfil div {
  width: 28vw;
  max-width: 110px;
  height: 28vw;
  max-height: 110px;
  border-radius: 50%;
  margin: 0 auto -20px;
  background-image: url(../assets/img/placeholder-user.jpg);
  background-size: contain;
  position: relative;
}

.foto-user div:after,
.foto-perfil div:after {
  content: '';
  position: absolute;
  width: 65%;
  height: 104%;
  background-image: url(../assets/img/shape-3.svg);
  background-size: contain;
  background-repeat: no-repeat;
  left: -8%;
  top: -18%;
}

.foto-user input {
  width: 30px;
  padding: 0;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  top: 2px;
  overflow: hidden;
}

.foto-perfil input {
  width: 30px;
  padding: 0;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  top: -55px;
  right: -59px;
}

.foto-user input:before,
.foto-perfil input:before {
  content: '+';
  text-align: center;
  font-size: 28px;
  line-height: 28px;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  color: #fff;
  background: rgba(71, 71, 71, 1);
  background: -moz-linear-gradient(top, rgba(71, 71, 71, 1) 0%, rgba(126, 126, 126, 1) 100%);
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(71, 71, 71, 1)),
    color-stop(100%, rgba(126, 126, 126, 1))
  );
  background: -webkit-linear-gradient(top, rgba(71, 71, 71, 1) 0%, rgba(126, 126, 126, 1) 100%);
  background: -o-linear-gradient(top, rgba(71, 71, 71, 1) 0%, rgba(126, 126, 126, 1) 100%);
  background: -ms-linear-gradient(top, rgba(71, 71, 71, 1) 0%, rgba(126, 126, 126, 1) 100%);
  background: linear-gradient(to bottom, rgba(71, 71, 71, 1) 0%, rgba(126, 126, 126, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#474747', endColorstr='#7e7e7e', GradientType=0 );
}

.foto-perfil input:before {
  content: '';
  background-image: url(../assets/img/gear.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

/* -- texto inferior de los formularios -- */
.text-form {
  margin-top: 3%;
}
.text-form,
.text-form p,
.text-form a,
.text-form a {
  color: #fff;
  font-size: 16px;
}
.text-form a {
  font-weight: bold;
}

.text-form label {
  width: 92%;
  text-align: left;
}

.text-form input {
  width: 20px;
  height: 20px;
  margin-right: 2%;
}

.skew-input select {
  background-color: transparent;
  color: #fff;
  height: 48px;
  margin: 2px 20px;
  border: none;
  outline: none;
}
.skew-input.select select {
  width: calc(100% - 40px);
}

.skew-input.select select option {
  background-color: #279bc2;
}

.skew-textarea {
  position: relative;
  margin-bottom: 100px;
  z-index: 1;
}

.skew-textarea:before {
  position: absolute;
  content: '';
  width: 180%;
  height: 100%;
  right: -15%;
  top: 3%;
  background-color: #fff;
  z-index: -1;
  transform: skewX(-25deg);
  -webkit-box-shadow: 0px 10px 30px 0px rgba(64, 126, 172, 1);
  -moz-box-shadow: 0px 10px 30px 0px rgba(64, 126, 172, 1);
  box-shadow: 0px 10px 30px 0px rgba(64, 126, 172, 1);
}

.skew-textarea textarea {
  min-height: 120px;
  border: none;
}

.skew-textarea .mensajeFecha {
  text-align: right;
  color: #279bc2;
}

@media (max-width: 650px) {
  .skew-input {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .foto-perfil input {
    top: -12vw;
    right: -14vw;
  }
}
